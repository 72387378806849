import React from "react";
import ContactUs from "../../Components/ContactUs/ContactUs";
import Products from "../../Components/ProductsCard/Products";
import PublicCarousel from "../../Components/PublicCarousel/PublicCarousel";
import RequestForService from "../../Components/RequestForService/RequestForService";
import NoticeCircularsButton from "../../Reuseable-Components/Notice&CircularsButton/NoticeCircularsButton";
import PublicCarousel_v2 from "../../Components/public_carousel_v2/Public_Carousel_v2";

export default function Home() {
  return (
    <>
      <NoticeCircularsButton />
      {/* <PublicCarousel /> */}
      <PublicCarousel_v2 />
      <RequestForService />
      <Products />
      {/* <FB_Carousel /> */}
      {/* <Blogs /> */}
      <ContactUs />
    </>
  );
}
