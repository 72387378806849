/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { useEffect } from "react";
import { useHref } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
const GET_NOTICE = "api/notice-circulars-homepages?populate=*";

export default function NoticeCircularsButton() {
  const { data, loading, error } = useAxios(GET_NOTICE);
  const [defaultTab, setDefalutTab] = useState(1);

  // console.log("Noticedata", data);

  const defaulttab = "bg-yellow-700";
  const nondefaulttab = "bg-yellow-700";
  return (
    <div className="fixed   top-[35%] md:top-1/2 right-0 z-50 ">
      <div className="flex flex-col gap-2">
        <div>
          <a
            className="relative float-right px-3 py-1.5 text-white font-sans rounded bg-red-700 hover:bg-red-600  items text-sm 2xl:text-base"
            href="https://files.siscobank.com/Branch%20Wise%20list%20of%20shareholders%20whose%20unpaid%20dividend%20is%20to%20be%20forfeited.pdf"
            target="_blank"
          >
            <div className="absolute top-2">
              <span class="relative flex h-3 w-3">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75 right-4 top-0.5"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-300 right-4 top-0.5"></span>
              </span>
            </div>
            <span className="font-semibold">UNCLAIMED DIVIDENDS</span>
          </a>
        </div>
        <div>
          <a
            className="relative float-right px-3 py-1.5 text-white font-sans rounded bg-[#A16207] hover:bg-[#be7106]    items text-sm 2xl:text-base"
            href="https://files.siscobank.com/Printfile_Donation_poster_37066506.pdf"
            target="_blank"
          >
            <div className="absolute top-2">
              {/* <span class="relative flex h-3 w-3">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75 right-4 top-0.5"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-300 right-4 top-0.5"></span>
              </span> */}
            </div>
            <span className="font-semibold">Donate to CM's Relief Fund</span>
          </a>
        </div>
        {data?.map((noticedata) => (
          <div>
            <button
              type="button"
              className={`float-right px-3 py-1.5 text-white font-sans rounded  hover:bg-[#be7106]  items text-sm 2xl:text-base  
              ${defaultTab === noticedata?.id ? defaulttab : nondefaulttab} `}
            >
              <a
                href={
                  noticedata?.attributes?.notice_circular_pdf?.data[0]
                    ?.attributes?.url
                }
                target="_blank"
              >
                {noticedata?.attributes?.title}
              </a>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
