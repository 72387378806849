import React from "react";
import { Routes, Route } from "react-router-dom";
import AwardsAndRecognitions from "./Components/AboutUs/AwardsAndRecognitions";
import History from "./Components/AboutUs/History";
import KeyMilestone from "./Components/AboutUs/KeyMilestone";
import EMI from "./Components/Calculator/EMI";
import FD from "./Components/Calculator/FD";
import RD from "./Components/Calculator/RD";
import Careers from "./Components/Careers/Careers";
import CheckRates from "./Components/CheckRates/CheckRates";
import FinancialInclusion from "./Components/FinancialInclusion/FinancialInclusion";
import Faq from "./Components/Forum/Faq";
import Rti from "./Components/Forum/Rti";
import VigilanceCell from "./Components/Forum/VigilanceCell";
import Accounts from "./Components/Prdoucts/Accounts";
import Deposits from "./Components/Prdoucts/Deposits";
import Loans from "./Components/Prdoucts/Loans";
import Home from "./Pages/Home/Home";
import PublicLayout from "./PublicLayout";
import Ombudsman from "./Components/GetInTouch/Ombudsman";
import UnclaimedDeposit from "./Components/GetInTouch/UnclaimedDeposit";
import AnnualReport from "./Components/AboutUs/AnnualReport";
import RequestService from "./Components/GetInTouch/RequestService";
import FeedbackGrievanceRedressal from "./Components/GetInTouch/FeedbackGrievanceRedressal";
import AnnualReportsArchive from "./Components/Archives/AnnualReportsArchive";
import Membership from "./Components/AboutUs/Membership";
import RequestMembership from "./Components/AboutUs/RequestMembership";
import Insurance from "./Components/Insurance/Insurance";
import CircularsandNotificationMedia from "./Components/Media/CircularsandNotificationMedia";
import NewsandEventsArchive from "./Components/Archives/NewsandEventsArchive";
import ContactUs from "./Components/GetInTouch/ContactUs";
import FindATMBranches from "./Components/GetInTouch/FindATMBranches";
import AGM_21_Notice from "./Components/Media/AGM_21_Notice";
import NewsAndEventsMedia from "./Components/Media/NewsAndEvents";
import CircularsAndNotificationsArchive from "./Components/Archives/CircularsAndNotificationsArchive";
import ChargesSavingsAccount from "./Components/Archives/ChargesSavingsAccount";
import ErrorPage from "./Reuseable-Components/ErrorPage";
import CovidReliefSiscoCustomers from "./Components/Archives/CovidReliefSiscoCustomers";
import PhotoGalleryMedia from "./Components/Media/PhotoGalleryMedia";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import CashKendraDetails from "./Components/FinancialInclusion/CashKendraDetails";
import Blogs from "./Components/Blogs/Blogs";
import DeafAccounts from "./Pages/DeafAccounts";

function App() {
  return (
    <>
      {/* <Navbar /> */}
      <Routes>
        <Route element={<PublicLayout />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/deaf-accounts" element={<DeafAccounts />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route
            exact
            path="/financial_inclusion"
            element={<FinancialInclusion />}
          />
          <Route
            exact
            path="/financial_inclusion/cashkendra_details"
            element={<CashKendraDetails />}
          />
          <Route path="/check_rates" element={<CheckRates />} />
          <Route exact path="/about_us/history" element={<History />} />

          <Route exact path="/about_us/membership" element={<Membership />} />
          <Route
            exact
            path="/about_us/awards_and_recognition"
            element={<AwardsAndRecognitions />}
          />
          <Route
            exact
            path="/about_us/keymilestone"
            element={<KeyMilestone />}
          />
          <Route
            exact
            path="/about_us/annual_report"
            element={<AnnualReport />}
          />
          <Route
            exact
            path="/about_us/membership/request_membership"
            element={<RequestMembership />}
          />

          <Route path="/products/accounts" element={<Accounts />} />
          <Route path="/products/deposits" element={<Deposits />} />
          <Route path="/products/loans" element={<Loans />} />

          <Route
            exact
            path="/insurance/pradhan_mantri_yojana"
            element={<Insurance />}
          />

          <Route
            exact
            path="/media/photo_gallery"
            element={<PhotoGalleryMedia />}
          />
          <Route
            exact
            path="/media/news_and_events"
            element={<NewsAndEventsMedia />}
          />
          <Route
            exact
            path="/media/circulars_and_notifications"
            element={<CircularsandNotificationMedia />}
          />

          <Route
            exact
            path="/media/news_and_events/21st_annual_general_body_meeting"
            element={<AGM_21_Notice />}
          />

          <Route
            exact
            path="/archives/annual_reports"
            element={<AnnualReportsArchive />}
          />

          <Route
            exact
            path="/archives/news_and_events"
            element={<NewsandEventsArchive />}
          />
          <Route
            exact
            path="/archives/circulars_and_notifications"
            element={<CircularsAndNotificationsArchive />}
          />

          <Route
            exact
            path="/archives/circulars_and_notifications/2019/charges_for_savings_account"
            element={<ChargesSavingsAccount />}
          />

          <Route
            exact
            path="/archives/circulars_and_notifications/2020/covid-19_relief_for_sisco_bank_customers"
            element={<CovidReliefSiscoCustomers />}
          />

          <Route
            exact
            path="/get_in_touch/contact_us"
            element={<ContactUs />}
          />

          <Route
            exact
            path="/get_in_touch/find_atms_or_branches"
            element={<FindATMBranches />}
          />

          <Route
            exact
            path="/get_in_touch/feedback_or_grievance_redressal"
            element={<FeedbackGrievanceRedressal />}
          />

          <Route
            exact
            path="/get_in_touch/request_for_service"
            element={<RequestService />}
          />

          <Route exact path="/get_in_touch/ombudsman" element={<Ombudsman />} />

          <Route
            exact
            path="/get_in_touch/unclaimed_deposit"
            element={<UnclaimedDeposit />}
          />

          <Route exact path="/forum/rti" element={<Rti />} />
          <Route exact path="/forum/faq" element={<Faq />} />
          <Route
            exact
            path="/forum/vigilance_cell"
            element={<VigilanceCell />}
          />

          <Route path="/calculator/emi_calculator" element={<EMI />} />
          <Route path="/calculator/fd_calculator" element={<FD />} />
          <Route path="/calculator/rd_calculator" element={<RD />} />

          <Route exact path="/error" element={<ErrorPage />} />
          <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />

          <Route exact path="/blogs" element={<Blogs />} />
          {/* <Route exact path="/blogCard" element={<BlogCard />} /> */}

          <Route exact path="/blogs/2" element={<Blogs />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
